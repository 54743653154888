import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthenticatedHeader from 'components/header/AuthenticatedHeader';
import Home from 'components/Home';
import Dashboard from 'components/Dashboard';

const AuthenticatedApp = () => {
  return (
    <>
      <AuthenticatedHeader />
      <Router>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </>
  );
};

export default AuthenticatedApp;
