/*global process*/
const SPOTIFY_BASE_URL = 'https://accounts.spotify.com';
const CLIENT_ID = process.env.CLIENT_ID;
// dev
const REDIRECT_URI = process.env.REDIRECT_URI;

// use to authorize access and get auth code. we can give it state
// example https://accounts.spotify.com/authorize?client_id=client-id-string&response_type=code&redirect_uri=https%3A%2F%2Fexample.com%2Fcallback&scope=user-read-private%20user-read-email&state=34fFs29kd09

// TODO: make scope less static so it's easier to add/subtract scope as needed
export const SPOTIFY_AUTH_URL = `${SPOTIFY_BASE_URL}/authorize/?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=playlist-modify-public`;

export const DELAY_FOR_LOCATION_LOADING = 1000;
export const DELAY_FOR_DEBOUNCE = 500;

export const abbrWeekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const abbrMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
