import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import PropTypes from 'prop-types';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import { DateRangePicker } from 'react-dates';
import datePickerTheme from './datePickerTheme';
import useViewport from 'hooks/useViewport';
ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme(datePickerTheme);

const DatePicker = ({ setValue }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const { width } = useViewport();
  const breakpoint = 640;

  useEffect(() => {
    if (startDate && endDate) {
      setValue('datePicker', { startDate: startDate._d, endDate: endDate._d });
    }
  }, [startDate, endDate]);

  return (
    <div
      css={theme =>
        theme.mq({
          width: '100%',
          display: 'flex',
          flexDirection: ['column', 'row'],
          alignItems: 'center',
          justifyContent: 'center',
          ['input#start-date::placeholder']: {
            textAlign: 'left'
          },
          ['input#end-date::placeholder']: {
            textAlign: 'right'
          },
          ['input#end-date']: {
            textAlign: 'right'
          }
        })
      }
    >
      <DateRangePicker
        startDate={startDate}
        startDateId="start-date"
        endDate={endDate}
        endDateId="end-date"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        numberOfMonths={width < breakpoint ? 1 : 2}
        hideKeyboardShortcutsPanel={true}
        daySize={width < breakpoint ? 34 : 40}
      />
    </div>
  );
};

DatePicker.propTypes = {
  setValue: PropTypes.func.isRequired
};

export default DatePicker;
