import { useStore } from 'context/store';

export const SET_LOCATION_DESCRIPTION = 'SET_LOCATION_DESCRIPTION';
export const SET_RAW_START_AND_END_DATE = 'SET_RAW_START_AND_END_DATE';
export const SET_IS_EXPANDED = 'SET_IS_EXPANDED';
export const HYDRATE_SEARCH = 'HYDRATE_SEARCH';
export const LOCATION_LOADING = 'LOCATION_LOADING';
export const LOCATION_OPTIONS_LOADING = 'LOCATION_OPTIONS_LOADING';

const useSearch = () => {
  const { state, dispatch } = useStore();
  const {
    locationDescription,
    rawStartAndEndDate,
    isExpanded,
    locationLoading,
    locationOptionsLoading
  } = state;
  return [
    {
      locationDescription,
      rawStartAndEndDate,
      isExpanded,
      locationLoading,
      locationOptionsLoading
    },
    {
      setLocationDescription: locationDescription =>
        dispatch({
          type: SET_LOCATION_DESCRIPTION,
          payload: locationDescription
        }),
      setRawStartAndEndDate: rawStartAndEndDate =>
        dispatch({
          type: SET_RAW_START_AND_END_DATE,
          payload: rawStartAndEndDate
        }),
      setIsExpanded: isExpanded =>
        dispatch({
          type: SET_IS_EXPANDED,
          payload: isExpanded
        }),
      setLocationLoading: locationLoading =>
        dispatch({
          type: LOCATION_LOADING,
          payload: locationLoading
        }),
      setLocationOptionsLoading: locationOptionsLoading =>
        dispatch({
          type: LOCATION_OPTIONS_LOADING,
          payload: locationOptionsLoading
        }),
      hydrateSearch: prevSearchState =>
        dispatch({
          type: HYDRATE_SEARCH,
          payload: prevSearchState
        })
    }
  ];
};

export default useSearch;
