import React from 'react';
import PropTypes from 'prop-types';
import { getLocationDetail } from 'services/location-api';

const DropdownItem = ({
  location,
  setLocation,
  setDropdownOpen,
  sessionToken
}) => {
  const { place_id, description } = location;

  const handleClick = () => {
    getLocationDetail(place_id, sessionToken, description).then(
      locationWithDetail => {
        setLocation(locationWithDetail);
        setDropdownOpen(false);
      }
    );
  };

  return (
    <li className="locationItem" onClick={() => handleClick()}>
      {description}
    </li>
  );
};

DropdownItem.propTypes = {
  location: PropTypes.shape({
    description: PropTypes.string.isRequired,
    place_id: PropTypes.string.isRequired
  }).isRequired,
  setLocation: PropTypes.func.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  sessionToken: PropTypes.string
};

export default DropdownItem;
