import styled from '@emotion/styled';

const StyledSearchForm = styled.form(({ theme, isExpanded }) => {
  return theme.mq({
    position: 'absolute',
    top: '100%',
    padding: isExpanded ? '8px 0px' : 0,
    maxHeight: isExpanded ? '300px' : 0,
    width: '100%',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    alignItems: ['center', 'center', 'flex-start'],
    transition: theme.transitions.allFast,
    ['button']: {
      color: theme.colors.white,
      backgroundColor: theme.colors.black,
      alignSelf: ['center', 'center', 'flex-start'],
      height: '3rem',
      width: [theme.dateRangeWidth, theme.dateRangeWidth, '100%'],
      border: 'none',
      borderRadius: '2px',
      fontSize: theme.fontSize[3],
      margin: ['.5rem 0', '.5rem 0', 0],
      padding: '.65rem .5rem'
    }
  });
});

export default StyledSearchForm;
