import { css } from '@emotion/core';
import theme from './theme';

const globalStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
  }

  body {
    font-family: Circular, Helvetica, Arial, sans-serif, IosFix;
    color: ${theme.colors.black};
    line-height: ${theme.lineHeights.body};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: ${theme.lineHeights.heading};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  input {
    font-size: 1rem;
  }
`;

export default globalStyles;
