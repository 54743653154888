import React, { useRef, useEffect } from 'react';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import queryString from 'querystring';
import useSearch from 'hooks/useSearch';
import useConcerts from 'hooks/useConcerts';
import { useAuth } from 'context/AuthContext';
import { get } from 'services/request';
import StyledSearchForm from './StyledSearchForm';
import DatePicker from './DatePicker';
import LocationPicker from './location/LocationPicker';
import SearchAccordion from 'components/search/accordion/SearchAccordion';
import geohash from 'ngeohash';

const SearchForm = () => {
  const { handleSubmit, control, setValue, errors } = useForm();
  const { user } = useAuth();
  const formEl = useRef(null);

  const [searchState, searchActions] = useSearch();
  const { locationDescription, isExpanded } = searchState;
  const {
    setLocationDescription,
    setRawStartAndEndDate,
    setIsExpanded
  } = searchActions;

  useEffect(() => {
    const setVisibility = () => (formEl.current.style.overflow = 'visible');
    const setHidden = () => (formEl.current.style.overflow = 'hidden');
    if (formEl && formEl.current) {
      if (!isExpanded) {
        formEl.current.removeEventListener('transitionend', setVisibility);
        setHidden();
      } else {
        formEl.current.addEventListener('transitionend', setVisibility);
      }
    }
    return () =>
      formEl.current.removeEventListener('transitionend', setVisibility);
  }, [isExpanded]);

  const [, concertsActions] = useConcerts();
  const { setConcertsLoading, setConcerts } = concertsActions;

  const onSubmit = ({ datePicker, locationPicker }) => {
    const { startDate, endDate } = datePicker;
    setRawStartAndEndDate({ startDate, endDate });
    // set end date to end of that day
    endDate.setHours(23, 59, 59);

    const formattedStartDate = datePicker.startDate
      .toISOString()
      // ticketmaster doesn't like milliseconds
      .replace(/\.000/, '');
    const formattedEndDate = datePicker.endDate
      .toISOString()
      // ticketmaster doesn't like milliseconds
      .replace(/\.000/, '');

    setLocationDescription(locationPicker.description);
    setConcertsLoading(true);
    setIsExpanded(false);

    const { lat, lng } = locationPicker;
    const geoPoint = geohash.encode(lat, lng);

    const query = queryString.stringify({
      geoPoint,
      startEndDateTime: `${formattedStartDate},${formattedEndDate}`,
      code: user ? user.code : null
    });

    get(`/concerts?${query}`).then(concerts => {
      // filtering events/concerts that only have spotifyIds for now
      // we still have some info (e.g. ticketmaster) for artists without spotifyIds
      // so maybe we do something with it in the future?
      setConcerts(concerts.filter(c => c.spotify.artistId));
      setConcertsLoading(false);
    });
  };

  return (
    <div
      css={theme =>
        theme.mq({
          position: 'sticky',
          top: [...theme.headerHeight],
          zIndex: 1,
          background: theme.colors.white
        })
      }
    >
      <SearchAccordion />
      <StyledSearchForm
        className="searchForm"
        ref={formEl}
        isExpanded={isExpanded}
        locationDescription={locationDescription}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          as={LocationPicker}
          name="locationPicker"
          control={control}
          defaultValue={null}
          setValue={setValue}
          rules={{ required: true }}
        />
        {/* //TODO: style error messages */}
        {/* <ErrorMessage errors={errors} name="locationPicker" message="Location required." /> */}
        <Controller
          as={DatePicker}
          name="datePicker"
          control={control}
          defaultValue={null}
          setValue={setValue}
          rules={{ required: true }}
        />
        {/* <ErrorMessage errors={errors} name="datePicker" message="Dates required." /> */}
        <button className="searchButton">Search</button>
      </StyledSearchForm>
    </div>
  );
};

export default SearchForm;
