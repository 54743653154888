import React from 'react';
import PropTypes from 'prop-types';
import CurrentLocationSVG from 'assets/CurrentLocation.svg';
import { getLocationFromCoords } from 'services/location-api';
import LoadingSpinnerAlt from 'components/shared/LoadingSpinnerAlt';
import { DELAY_FOR_LOCATION_LOADING } from 'utils/constants';
import useSearch from 'hooks/useSearch';

const CurrentLocationItem = ({ setLocation, setDropdownOpen }) => {
  const [{ locationLoading }, { setLocationLoading }] = useSearch();

  const handleClick = () => {
    const locationLoadingTimeout = setTimeout(
      () => setLocationLoading(true),
      DELAY_FOR_LOCATION_LOADING
    );

    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      // TODO: probably want some error handling here
      getLocationFromCoords(
        latitude.toFixed(7).toString(),
        longitude.toFixed(7).toString()
      )
        .then(location => {
          clearTimeout(locationLoadingTimeout);
          setLocationLoading(false);
          setLocation(location);
          setDropdownOpen(false);
        })
        .catch(err => console.error(err));
    });
  };

  return (
    <li
      className="locationItem"
      id="currentLocation"
      onClick={() => handleClick()}
    >
      <CurrentLocationSVG className="currentLocationIcon" />
      Current Location
      {locationLoading && <LoadingSpinnerAlt />}
    </li>
  );
};

CurrentLocationItem.propTypes = {
  setLocation: PropTypes.func.isRequired,
  setDropdownOpen: PropTypes.func.isRequired
};

export default CurrentLocationItem;
