import React from 'react';
import PropTypes from 'prop-types';
import useConcerts from 'hooks/useConcerts';
import { abbrMonths, abbrWeekdays } from 'utils/constants';
import { normalizeDateStringFormat, transformToCardTime } from 'utils/dates';

const Concert = ({ concert, register }) => {
  // need to handle when there are multiple events/shows
  const event = concert.events[0];

  const fullDate = new Date(normalizeDateStringFormat(event.date));
  const weekday = abbrWeekdays[fullDate.getDay()];
  const date = fullDate.getDate();
  const month = abbrMonths[fullDate.getMonth()];

  const time = transformToCardTime(event.time);

  const [{ artistSelection }] = useConcerts();
  const isDefaultChecked = !!artistSelection[concert.artist];

  return (
    <li className="concertCard">
      <div className="checkBoxContainer">
        <input
          className="checkBox"
          type="checkbox"
          id={concert.artist}
          name="artists"
          ref={register}
          value={concert.spotify.artistId}
          defaultChecked={isDefaultChecked}
        />
      </div>
      <label htmlFor={concert.artist} className="concertDetailsLabel">
        <div className="concertArtist">{concert.artist}</div>
        <div className="concertDateAndTime">
          <p className="concertDate">{`${month} ${date}`}</p>
          <p className="concertDayAndTime">{`${weekday} • ${time}`}</p>
        </div>
        <p className="concertVenue">{`${event.venue} - ${event.city}, ${event.state}`}</p>
      </label>
    </li>
  );
};

Concert.propTypes = {
  concert: PropTypes.object,
  register: PropTypes.func
};

export default Concert;
