import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import UnauthenticatedHeader from 'components/header/UnauthenticatedHeader';
import Home from 'components/Home';
import Callback from 'components/Callback';

const UnauthenticatedApp = () => {
  return (
    <>
      <UnauthenticatedHeader />
      <Router>
        <Switch>
          <Route path="/callback" component={Callback} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </>
  );
};

export default UnauthenticatedApp;
