import React from 'react';
import { useAuth } from 'context/AuthContext';
import StyledHeader from './StyledHeader';
import SpotifyLogo from 'assets/SpotifyLogo.svg';

function AuthenticatedHeader() {
  const { user } = useAuth();
  const { external_urls, display_name, images } = user;
  const profilePic = images[0]
    ? images[0].url
    : 'assets/default-profile-pic.png';

  return (
    <StyledHeader>
      <div className="content">
        <SpotifyLogo className="logo" />
        <a
          className="userContainer"
          href={external_urls.spotify}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div>
            <p>Signed in as:</p>
            <p>{display_name}</p>
          </div>
          <img
            className="avatar"
            alt={`${display_name} profile avatar`}
            src={profilePic}
          />
        </a>
      </div>
    </StyledHeader>
  );
}

export default AuthenticatedHeader;
