import React from 'react';
import StyledSearchAccordion from './SyledSearchAccordion';
import ChevronDown from 'assets/ChevronDown.svg';
import ChevronUp from 'assets/ChevronUp.svg';
import useSearch from 'hooks/useSearch';

const SearchAccordion = () => {
  const [{ locationDescription, isExpanded }, { setIsExpanded }] = useSearch();

  return (
    <StyledSearchAccordion
      isExpanded={isExpanded}
      locationDescription={locationDescription}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <h2>{`Shows near ${locationDescription}`}</h2>
      {isExpanded ? (
        <ChevronUp className="chevronUp" />
      ) : (
        <ChevronDown className="chevronDown" />
      )}
    </StyledSearchAccordion>
  );
};

export default SearchAccordion;
