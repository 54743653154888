import { get } from './request';
import queryString from 'querystring';

export const getLocationOptions = (input, sessionToken) =>
  get(`/locations/city?${queryString.stringify({ input, sessionToken })}`);

export const getLocationFromZipcode = zipcode =>
  get(`/locations/zipcode?${queryString.stringify({ zipcode })}`);

export const getLocationFromCoords = (lat, lng) =>
  get(`/locations/latlng?${queryString.stringify({ lat, lng })}`);

export const getLocationDetail = (place_id, sessionToken, description) =>
  get(
    `/locations/place?${queryString.stringify({
      place_id,
      sessionToken,
      description
    })}`
  );
