import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledLoadingSpinner = styled.div(({ theme }) => {
  return theme.mq({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ['.container']: {
      display: 'inline-block',
      position: 'relative',
      width: '80px',
      height: '80px',
      ['div']: {
        transformOrigin: '40px 40px',
        animation: 'lds-spinner 1.2s linear infinite'
      },
      ['div:after']: {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: '3px',
        left: '37px',
        width: '6px',
        height: '18px',
        borderRadius: '20%',
        background: theme.colors.lightGreen
      },
      ['div:nth-of-type(1)']: {
        transform: 'rotate(0deg)',
        animationDelay: '-1.1s'
      },
      ['div:nth-of-type(2)']: {
        transform: 'rotate(30deg)',
        animationDelay: '-1s'
      },
      ['div:nth-of-type(3)']: {
        transform: 'rotate(60deg)',
        animationDelay: '-0.9s'
      },
      ['div:nth-of-type(4)']: {
        transform: 'rotate(90deg)',
        animationDelay: '-0.8s'
      },
      ['div:nth-of-type(5)']: {
        transform: 'rotate(120deg)',
        animationDelay: '-0.7s'
      },
      ['div:nth-of-type(6)']: {
        transform: 'rotate(150deg)',
        animationDelay: '-0.6s'
      },
      ['div:nth-of-type(7)']: {
        transform: 'rotate(180deg)',
        animationDelay: '-0.5s'
      },
      ['div:nth-of-type(8)']: {
        transform: 'rotate(210deg)',
        animationDelay: '-0.4s'
      },
      ['div:nth-of-type(9)']: {
        transform: 'rotate(240deg)',
        animationDelay: '-0.3s'
      },
      ['div:nth-of-type(10)']: {
        transform: 'rotate(270deg)',
        animationDelay: '-0.2s'
      },
      ['div:nth-of-type(11)']: {
        transform: 'rotate(300deg)',
        animationDelay: '-0.1s'
      },
      ['div:nth-of-type(12)']: {
        transform: 'rotate(330deg)',
        animationDelay: '0s'
      }
    },

    ['@keyframes lds-spinner']: {
      '0%': {
        opacity: 1
      },
      '100%': {
        opacity: 0
      }
    }
  });
});

const LoadingSpinner = ({ theme }) => {
  return (
    <StyledLoadingSpinner theme={theme}>
      <div className="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </StyledLoadingSpinner>
  );
};

LoadingSpinner.propTypes = {
  theme: PropTypes.object
};

export default LoadingSpinner;
