import styled from '@emotion/styled';

const StyledHeader = styled.header(({ theme }) => {
  return theme.mq({
    position: 'fixed',
    top: 0,
    background: theme.colors.black,
    fontSize: [theme.fontSize[1], theme.fontSize[2], theme.fontSize[3]],
    fontWeight: theme.fontWeights.heading,
    width: '100%',
    zIndex: 1,
    height: [...theme.headerHeight],
    ['.content']: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: ['1rem', '2rem'],
      paddingRight: ['1rem', '2rem'],
      ['.logo']: {
        height: '70%'
      },
      ['.userContainer']: {
        color: theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        cursor: 'pointer',
        [':hover, :active']: {
          color: theme.colors.green
        },
        ['.avatar']: {
          height: '70%',
          borderRadius: '50%',
          marginLeft: '.5rem'
        }
      }
    }
  });
});

export default StyledHeader;
