import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import combineReducers from './utils/combineReducers';
import { searchReducer, searchInitialState } from '../reducers/searchReducer';
import {
  concertsReducer,
  concertsInitialState
} from '../reducers/concertsReducer';

const StoreContext = createContext();

const combinedReducers = combineReducers([searchReducer, concertsReducer]);
const combinedInitialState = { ...searchInitialState, ...concertsInitialState };

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combinedReducers, combinedInitialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const useStore = () => useContext(StoreContext);
