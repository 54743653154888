import React from 'react';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import styled from '@emotion/styled';
import globalStyles from 'config/globalStyles';
import theme from 'config/theme';
import { useAuth } from 'context/AuthContext';
import AuthenticatedApp from 'components/authenticated/AuthenticatedApp';
import UnauthenticatedApp from 'components/unauthenticated/UnauthenticatedApp';

const AppLayout = styled.div(({ theme }) =>
  theme.mq({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh'
  })
);

export default function App() {
  const { user } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <AppLayout>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </AppLayout>
    </ThemeProvider>
  );
}
