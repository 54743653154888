import {
  SET_LOCATION_DESCRIPTION,
  SET_RAW_START_AND_END_DATE,
  SET_IS_EXPANDED,
  LOCATION_LOADING,
  LOCATION_OPTIONS_LOADING,
  HYDRATE_SEARCH
} from 'hooks/useSearch';

export const searchInitialState = {
  locationDescription: '',
  rawStartAndEndDate: {},
  isExpanded: true,
  locationLoading: false,
  locationOptionsLoading: false
};

export const searchReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOCATION_DESCRIPTION:
      return { ...state, locationDescription: payload };
    case SET_RAW_START_AND_END_DATE:
      return { ...state, rawStartAndEndDate: payload };
    case SET_IS_EXPANDED:
      return { ...state, isExpanded: payload };
    case LOCATION_LOADING:
      return { ...state, locationLoading: payload };
    case LOCATION_OPTIONS_LOADING:
      return { ...state, locationOptionsLoading: payload };
    case HYDRATE_SEARCH:
      return { ...state, ...payload };
    default:
      return state;
  }
};
