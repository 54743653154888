import React from 'react';

const PoweredByGoogleItem = () => {
  return (
    <li className="locationItem">
      <img
        src="assets/powered_by_google_on_white.png"
        alt="powered by Google image"
      />
    </li>
  );
};

export default PoweredByGoogleItem;
