import 'whatwg-fetch';
import React from 'react';
import { render } from 'react-dom';
import App from 'components/App';
import { StoreProvider } from 'context/store';
import { AuthProvider } from 'context/AuthContext';

render(
  <StoreProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </StoreProvider>,
  document.getElementById('root')
);
