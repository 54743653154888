import React from 'react';
import useSearch from 'hooks/useSearch';

const NoConcerts = () => {
  const [{ locationDescription }] = useSearch();
  return (
    <div className="noConcertsContainer">
      <p>{`No concerts found near ${locationDescription}.`}</p>
      <p>Bummer!</p>
    </div>
  );
};

export default NoConcerts;
