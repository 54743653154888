import {
  SET_CONCERTS_LOADING,
  SET_PLAYLIST_LOADING,
  SET_CONCERTS,
  SET_IS_MODAL_OPEN,
  SET_ARTIST_SELECTION,
  SET_TRACK_COUNT,
  SET_ARTIST_COUNT,
  SET_PLAYLIST_URL,
  SET_PLAYLIST_NAME,
  ADD_PLAYLIST,
  HYDRATE_CONCERTS
} from 'hooks/useConcerts';

export const concertsInitialState = {
  concertsLoading: false,
  playlistLoading: false,
  concerts: [],
  isModalOpen: false,
  artistSelection: {},
  trackCount: 0,
  artistCount: 0,
  playlistUrl: null,
  playlistName: '',
  playlists: []
};

export const concertsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CONCERTS_LOADING:
      return { ...state, concertsLoading: payload };
    case SET_PLAYLIST_LOADING:
      return { ...state, playlistLoading: payload };
    case SET_CONCERTS:
      return { ...state, concerts: payload };
    case SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: payload };
    case SET_ARTIST_SELECTION:
      return { ...state, artistSelection: payload };
    case SET_TRACK_COUNT:
      return { ...state, trackCount: payload };
    case SET_ARTIST_COUNT:
      return { ...state, artistCount: payload };
    case SET_PLAYLIST_URL:
      return { ...state, playlistUrl: payload };
    case SET_PLAYLIST_NAME:
      return { ...state, playlistName: payload };
    case ADD_PLAYLIST:
      return { ...state, playlists: [...state.playlists, payload] };
    case HYDRATE_CONCERTS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
