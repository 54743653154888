export const enforceFullDateString = rawDateString =>
  rawDateString.split('/').reduce((dateString, datePart) => {
    if (datePart.length === 4) return dateString + datePart;
    if (datePart < 10) return dateString + '0' + datePart + '/';
    else return dateString + datePart + '/';
  }, '');

export const todayAsUSLocaleDateString = () => {
  const newDate = new Date();
  return newDate.toLocaleDateString('en-us');
};

export const enforcedLocaleDateString = dateString => {
  return enforceFullDateString(dateString);
};

export const daysFromTodayAsLocaleString = dayOffset => {
  const newDate = daysFromToday(dayOffset);
  return newDate.toLocaleDateString('en-us');
};

export const daysFromToday = dayOffset => {
  const newDate = new Date();
  const updatedDate = newDate.setDate(newDate.getDate() + dayOffset);
  return new Date(updatedDate);
};

export const transformToCardTime = originalTimeString => {
  let dayPeriod = 'am';
  let [hours, minutes] = originalTimeString.split(':');

  if (hours === '12') {
    dayPeriod = 'pm';
  } else if (hours === '24') {
    hours = '12';
    dayPeriod = 'am';
  } else if (hours > 12) {
    hours = hours - 12;
    dayPeriod = 'pm';
  }

  return `${hours}:${minutes}${dayPeriod}`;
};

export const normalizeDateStringFormat = originalDateString => {
  const [year, month, day] = originalDateString.split('-');
  return `${month}/${day}/${year}`;
};
