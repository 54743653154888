import React from 'react';
import PropTypes from 'prop-types';

const Container = props => {
  return <div {...props}>{props.children}</div>;
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Container;
