import styled from '@emotion/styled';

const StyledConcerts = styled.form(({ theme }) => {
  return theme.mq({
    width: '100%',
    height: '100%',
    marginBottom: 72,
    transition: theme.transitions.allFast,
    ['ul']: {
      height: '100%',
      ['.concertCard']: {
        display: 'grid',
        gridTemplateColumns: '3rem 1fr',
        fontSize: [theme.fontSize[1], theme.fontSize[2], theme.fontSize[3]],
        borderTop: `1px solid ${theme.colors.lightGray}`,
        ['.checkBoxContainer']: {
          gridRow: '1/3',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ['.checkBox']: {
            height: '40%',
            width: '40%'
          }
        },
        ['.concertDetailsLabel']: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ['.concertArtist']: {
            height: '1.4rem',
            fontSize: theme.fontSize[2],
            fontWeight: theme.fontWeights.bold,
            color: theme.colors.green
          },
          ['.concertDateAndTime']: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ['.concertDate']: {
              marginRight: '.5rem'
            },
            ['.concertDayAndTime']: {
              color: theme.colors.darkGray
            }
          },
          ['.concertVenue']: {
            fontSize: theme.fontSize[0],
            color: theme.colors.gray
          }
        }
      },
      ['.concertCard:first-of-type']: {
        borderTop: 'none'
      }
    },
    ['.noConcertsContainer']: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.space[4],
      ['p']: {
        fontSize: theme.fontSize[3],
        fontWeight: theme.fontWeights.bold,
        textAlign: 'center',
        color: theme.colors.green
      }
    }
  });
});

export default StyledConcerts;
