import { useStore } from 'context/store';

export const SET_CONCERTS_LOADING = 'SET_CONCERTS_LOADING';
export const SET_PLAYLIST_LOADING = 'SET_PLAYLIST_LOADING';
export const SET_CONCERTS = 'SET_CONCERTS';
export const SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN';
export const SET_ARTIST_SELECTION = 'SET_ARTIST_SELECTION';
export const SET_TRACK_COUNT = 'SET_TRACK_COUNT';
export const SET_ARTIST_COUNT = 'SET_ARTIST_COUNT';
export const SET_PLAYLIST_URL = 'SET_PLAYLIST_URL';
export const SET_PLAYLIST_NAME = 'SET_PLAYLIST_NAME';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const HYDRATE_CONCERTS = 'HYDRATE_CONCERTS';

const useConcerts = () => {
  const { state, dispatch } = useStore();
  const {
    concertsLoading,
    playlistLoading,
    concerts,
    playlists,
    artistSelection,
    isModalOpen,
    trackCount,
    artistCount,
    playlistUrl,
    playlistName
  } = state;
  return [
    {
      concertsLoading,
      playlistLoading,
      concerts,
      playlists,
      artistSelection,
      isModalOpen,
      trackCount,
      artistCount,
      playlistUrl,
      playlistName
    },
    {
      setConcertsLoading: concertsLoading =>
        dispatch({
          type: SET_CONCERTS_LOADING,
          payload: concertsLoading
        }),
      setPlaylistLoading: playlistLoading =>
        dispatch({
          type: SET_PLAYLIST_LOADING,
          payload: playlistLoading
        }),
      setConcerts: concerts =>
        dispatch({
          type: SET_CONCERTS,
          payload: concerts
        }),
      setIsModalOpen: isModalOpen =>
        dispatch({
          type: SET_IS_MODAL_OPEN,
          payload: isModalOpen
        }),
      setArtistSelection: artistSelection =>
        dispatch({
          type: SET_ARTIST_SELECTION,
          payload: artistSelection
        }),
      setTrackCount: trackCount =>
        dispatch({
          type: SET_TRACK_COUNT,
          payload: trackCount
        }),
      setArtistCount: artistCount =>
        dispatch({
          type: SET_ARTIST_COUNT,
          payload: artistCount
        }),
      setPlaylistUrl: playlistUrl =>
        dispatch({
          type: SET_PLAYLIST_URL,
          payload: playlistUrl
        }),
      setPlaylistName: playlistName =>
        dispatch({
          type: SET_PLAYLIST_NAME,
          payload: playlistName
        }),
      addPlaylist: playlistUrl =>
        dispatch({
          type: ADD_PLAYLIST,
          payload: playlistUrl
        }),
      hydrateConcerts: prevConcertsState =>
        dispatch({
          type: HYDRATE_CONCERTS,
          payload: prevConcertsState
        })
    }
  ];
};

export default useConcerts;
