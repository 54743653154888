import styled from '@emotion/styled';

const StyledGenerateContainer = styled.div(
  ({ theme, isSelection, locationDescription }) => {
    const isDisabled = !isSelection;
    return theme.mq({
      position: 'fixed',
      height: [...theme.footerHeight],
      width: '100vw',
      left: 0,
      bottom: theme.space[2],
      display: 'flex',
      justifyContent: 'center',
      ['button']: {
        visibility: `${locationDescription ? 'visible' : 'hidden'}`,
        color: `${isDisabled ? theme.colors.gray : theme.colors.lightGreen}`,
        backgroundColor: `${
          isDisabled ? theme.colors.darkGray : theme.colors.black
        }`,
        alignSelf: 'center',
        border: `1px solid ${
          isDisabled ? theme.colors.darkGray : theme.colors.black
        }`,
        borderRadius: 32,
        width: '50vw',
        maxWidth: 300,
        fontSize: theme.fontSize[3],
        height: '3rem',
        padding: '.75rem'
      }
    });
  }
);

export default StyledGenerateContainer;
