import React, { useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import Container from './shared/Container';
import { get } from 'services/request';

const Dashboard = () => {
  const { user } = useAuth();

  useEffect(() => {
    get(`/playlists/${user.id}?code=${user.code}`).then(response => {
      console.log(response);
    });
  }, []);

  return (
    <Container
      css={theme =>
        theme.mq({
          position: 'relative',
          top: [...theme.headerHeight],
          height: [...theme.bodyHeight],
          width: '100%',
          maxWidth: [720, 1080],
          margin: '0 auto',
          padding: theme.space[3],
          paddingBottom: 0,
          overflowY: 'auto'
        })
      }
    >
      <h1>I&apos;m a Dashboard!</h1>
    </Container>
  );
};

export default Dashboard;
