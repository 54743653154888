import { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { withRouter } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

const Callback = ({ history }) => {
  const { handleCallback } = useAuth();
  const { ['?code']: code, state } = queryString.parse(window.location.search);

  useEffect(() => {
    handleCallback(code, state);
    history.push('/');
  });

  return null;
};

Callback.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Callback);
