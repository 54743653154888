import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';

const Overlay = styled.div(({ theme }) =>
  theme.mq({
    zIndex: 98,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  })
);

const ModalWrapper = styled.div(({ theme }) =>
  theme.mq({
    zIndex: 99,

    position: 'relative',
    width: 400,
    maxWidth: '90vw',
    height: 200,
    maxHeight: 500,
    margin: '0 auto'
  })
);

const Body = styled.div(({ theme }) =>
  theme.mq({
    padding: '20px 24px',
    borderRadius: 4,
    backgroundColor: 'white',
    height: '100%',
    width: '100%'
  })
);

const Button = styled.div(({ theme }) =>
  theme.mq({
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
    border: 0,
    background: 'none',
    color: 'black',
    cursor: 'pointer'
  })
);

const Modal = ({ children, isModalOpen, setIsModalOpen, isLoading }) => {
  const modalEl = useRef(null);

  const handleClickOutside = event => {
    if (modalEl.current && !modalEl.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const markup = isModalOpen && (
    <Overlay>
      <ModalWrapper className="modalWrapper" ref={modalEl}>
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          X
        </Button>
        <Body>{isLoading ? <LoadingSpinner /> : children}</Body>
      </ModalWrapper>
    </Overlay>
  );

  return createPortal(markup, document.body);
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired
};

export default Modal;
