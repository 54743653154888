import React from 'react';
import { useAuth } from 'context/AuthContext';
import StyledHeader from './StyledHeader';
import SpotifyLogo from 'assets/SpotifyLogo.svg';

function AuthenticatedHeader() {
  const { handleLogin } = useAuth();
  return (
    <StyledHeader>
      <div className="content">
        <SpotifyLogo className="logo" />
        <div className="userContainer">
          <p onClick={handleLogin}>Sign in with Spotify</p>
        </div>
      </div>
    </StyledHeader>
  );
}

export default AuthenticatedHeader;
