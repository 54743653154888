import styled from '@emotion/styled';

export default styled.div(({ theme, locationDescription }) => {
  return theme.mq({
    height: '2.5rem',
    padding: theme.space[2],
    width: '100%',
    display: locationDescription ? 'flex' : 'none',
    justifyContent: 'space-between',
    fontSize: [theme.fontSize[1], theme.fontSize[1], theme.fontSize[2]],

    '.chevronDown': {
      height: '100%',
      fontSize: '1rem'
    },
    '.chevronUp': {
      height: '100%',
      fontSize: '1rem'
    }
  });
});
