import React, { useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import { useForm } from 'react-hook-form';
import useSearch from 'hooks/useSearch';
import useConcerts from 'hooks/useConcerts';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Concert from './Concert';
import NoConcerts from './NoConcerts';
import StyledConcerts from './StyledConcerts';
import StyledGenerateContainer from './StyledGenerateContainer';
import PlaylistModal from './PlaylistModal';
import { post } from 'services/request';

const Concerts = () => {
  const { user } = useAuth();
  const [searchState] = useSearch();
  const [concertsState, concertsActions] = useConcerts();
  const {
    concertsLoading,
    concerts,
    isModalOpen,
    artistSelection,
    playlistUrl
  } = concertsState;
  const {
    setIsModalOpen,
    setArtistSelection,
    setTrackCount,
    setArtistCount,
    setPlaylistUrl,
    setPlaylistLoading,
    addPlaylist
  } = concertsActions;

  const [{ locationDescription }] = useSearch();
  const { handleSubmit, register, watch, getValues } = useForm();

  useEffect(() => {
    if (!isModalOpen && playlistUrl) {
      setPlaylistUrl(null);
    }
  }, [isModalOpen]);

  const selection = watch('artists');
  const isSelection = !!selection && selection.length > 0;

  const onGenerate = ({ artists }) => {
    let numberOfTracks = 0;
    const artistsIds = Array.isArray(artists) ? artists : [artists];
    const artistsWithTracks = artistsIds.reduce((acc, cur) => {
      const artist = concerts.find(concert => concert.spotify.artistId === cur);
      const trackUris = artist.spotify.topTracks
        .slice(0, 3)
        .map(track => track.uri);

      numberOfTracks += trackUris.length;

      acc[artist.artist] = {
        id: artist.spotify.artistId,
        trackUris
      };
      return acc;
    }, {});

    setArtistCount(artistsIds.length);
    setTrackCount(numberOfTracks);
    setArtistSelection(artistsWithTracks);

    setIsModalOpen(true);
  };

  const onCreatePlaylist = () => {
    if (!user)
      return window.alert('Sign in with Spotify to create this playlist');

    const playlistName = getValues('playlistName');

    const tracks = Object.values(artistSelection).map(
      artist => artist.trackUris
    );

    const events = concerts.reduce((events, concert) => {
      const { artist, spotify, url } = concert;
      if (artistSelection[artist]) {
        return [...events, { artist, spotifyArtistId: spotify.artistId, url }];
      } else return events;
    }, []);

    const body = {
      userId: user.id,
      tracks: tracks.flat(),
      playlistName,
      events
    };

    setPlaylistLoading(true);

    return post(`/playlists/create?code=${user.code}`, body).then(res => {
      const { playlistUrl } = res;
      addPlaylist(playlistUrl);
      setPlaylistUrl(playlistUrl);
      setPlaylistLoading(false);
    });
  };

  const hasSearchedNoConcerts =
    locationDescription && concerts && concerts.length < 1;

  if (concertsLoading)
    return (
      <div
        css={theme =>
          theme.mq({
            marginTop: theme.space[5]
          })
        }
      >
        <LoadingSpinner />
      </div>
    );

  return (
    <StyledConcerts onSubmit={handleSubmit(onGenerate)}>
      {hasSearchedNoConcerts ? (
        <NoConcerts />
      ) : (
        concerts && (
          <ul className="concerts">
            {concerts.map(concert => (
              <Concert
                key={concert.artist}
                concert={concert}
                register={register}
              />
            ))}
          </ul>
        )
      )}
      <StyledGenerateContainer
        isSelection={isSelection}
        locationDescription={locationDescription}
      >
        <button className="createCtaButton" disabled={!isSelection}>
          Generate
        </button>
      </StyledGenerateContainer>
      {isModalOpen && (
        <PlaylistModal
          register={register}
          onCreatePlaylist={onCreatePlaylist}
          watch={watch}
          searchState={searchState}
          concertsState={concertsState}
          concertsActions={concertsActions}
        />
      )}
    </StyledConcerts>
  );
};

export default Concerts;
