import React from 'react';
import PropTypes from 'prop-types';
import DropdownItem from './DropdownItem';
import CurrentLocationItem from './CurrentLocationItem';
import PoweredByGoogleItem from './PoweredByGoogleItem';

const DropdownList = ({
  locationOptions,
  setLocation,
  setDropdownOpen,
  sessionToken
}) => {
  const dropdownItems = locationOptions.map(location => {
    return (
      <DropdownItem
        key={location.place_id}
        setLocation={setLocation}
        setDropdownOpen={setDropdownOpen}
        location={location}
        sessionToken={sessionToken}
      />
    );
  });

  return (
    <ul className="dropdown">
      <CurrentLocationItem
        setLocation={setLocation}
        setDropdownOpen={setDropdownOpen}
      />
      {dropdownItems}
      <PoweredByGoogleItem />
    </ul>
  );
};

DropdownList.propTypes = {
  locationOptions: PropTypes.array.isRequired,
  setLocation: PropTypes.func.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  sessionToken: PropTypes.string
};

export default DropdownList;
