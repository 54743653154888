import styled from '@emotion/styled';

const StyledLocationPicker = styled.div(({ theme }) => {
  return theme.mq({
    width: [theme.dateRangeWidth, theme.dateRangeWidth, '100%'],
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: ['.5rem 0', '.5rem 0', 0],
    ['.locationInput']: {
      width: '100%',
      position: 'relative',
      height: '3rem',
      fontSize: theme.fontSize[3],
      padding: '.5rem',
      border: `1px solid ${theme.colors.gray}`,
      borderRadius: '2px'
    },
    ['.dropdown']: {
      zIndex: '1',
      backgroundColor: theme.colors.white,
      width: '100%',
      maxHeight: '12.75rem',
      overflowY: 'auto',
      position: 'absolute',
      top: '100%',
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.colors.gray}`,
      ['.locationItem']: {
        position: 'relative',
        padding: '.65rem .5rem',
        ['.currentLocationIcon']: {
          width: '16px',
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '9px',
          ['path']: {
            fill: theme.colors.green
          }
        }
      }
    }
  });
});

export default StyledLocationPicker;
