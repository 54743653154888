import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getUser } from 'utils/auth';
import * as auth from 'utils/auth';
import theme from 'config/theme';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useSearch from 'hooks/useSearch';
import useConcerts from 'hooks/useConcerts';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    status: 'idle',
    user: null,
    error: null
  });

  const [, { hydrateSearch }] = useSearch();
  const [, { hydrateConcerts }] = useConcerts();

  useEffect(() => {
    setState({ ...state, status: 'pending' });
    getUser().then(
      user => setState({ ...state, status: 'resolved', user }),
      error => setState({ ...state, status: 'error', error })
    );
  }, []);

  const handleLogin = prevStateCode => {
    setState({ ...state, status: 'pending' });
    auth.login(prevStateCode);
  };

  const handleCallback = (code, prevStateCode) => {
    setState({ ...state, status: 'pending' });
    const prevState = prevStateCode
      ? JSON.parse(window.localStorage.getItem(prevStateCode))
      : null;
    if (prevState) {
      hydrateSearch(prevState.searchState);
      hydrateConcerts(prevState.concertsState);
      window.localStorage.removeItem(prevStateCode);
    }
    auth.callback(code).then(
      // this is where the user is getting set
      user => setState({ ...state, status: 'resolved', user }),
      error => setState({ ...state, status: 'error', error })
    );
  };

  const isLoading = state.status === 'idle' || state.status === 'pending';
  const isError = state.status === 'error';
  const isSuccess = state.status === 'resolved';

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw'
        }}
      >
        <LoadingSpinner theme={theme} />
      </div>
    );
  }

  if (isError) {
    return <p>There&apos;s been an error - {status.error}</p>;
  }

  if (isSuccess) {
    return (
      <AuthContext.Provider value={{ ...state, handleLogin, handleCallback }}>
        {children}
      </AuthContext.Provider>
    );
  }
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
