import facepaint from 'facepaint';

const breakpoints = [40, 64];
const mq = facepaint(breakpoints.map(bp => `@media (min-width: ${bp}em)`));

const theme = {
  breakpoints,
  colors: {
    // pallette
    // https://coolors.co/191414-3d3b3c-7f7979-413f54-1db954-1ed75f
    black: '#191414',
    white: '#fff',
    darkGray: '#3d3b3c',
    gray: '#7f7979',
    lightGray: '#e0e0e0',
    darkBlue: '#413f54',
    green: '#1DB954',
    lightGreen: '#1ed75f'
  },
  // size in pixels = [12, 14, 16, 20, 24, 32, 48, 64]
  fontSize: [
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '3rem',
    '4rem'
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  letterSpacing: {},
  transitions: {
    allFast: 'all ease-in-out 300ms'
  },
  mq,
  // size in pixels = [0, 4, 8, 16, 32, 64, 128, 256]
  space: ['0rem', '0.25rem', '0.5em', '1rem', '2rem', '4rem', '8rem', '16rem'],
  dateRangeWidth: '286px',
  headerHeight: [65, 75, 85],
  footerHeight: [65, 75, 85],
  get bodyHeight() {
    return [
      `calc(100vh - ${this.headerHeight[0] + this.footerHeight[0]}px)`,
      `calc(100vh - ${this.headerHeight[1] + this.footerHeight[1]}px)`,
      `calc(100vh - ${this.headerHeight[2] + this.footerHeight[2]}px)`
    ];
  },
  fullSearchFormHeight: '13rem',
  collapsedSearchFormHeight: '2rem',
  headingHeight: '2.5rem'
};

export default theme;
