import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useAuth } from 'context/AuthContext';
import Modal from 'components/shared/Modal';
import theme from 'config/theme';

const PlaylistModal = ({
  register,
  onCreatePlaylist,
  watch,
  searchState,
  concertsState,
  concertsActions
}) => {
  const { user, handleLogin } = useAuth();
  const {
    isModalOpen,
    playlistLoading,
    playlistUrl,
    artistCount,
    trackCount,
    playlistName
  } = concertsState;
  const { setIsModalOpen, setPlaylistName } = concertsActions;

  useEffect(() => {
    setPlaylistName(watch('playlistName'));
  }, [watch('playlistName')]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isLoading={playlistLoading}
    >
      {playlistUrl ? (
        <div>
          <h1>Now Playlisting</h1>
          <a
            href={playlistUrl}
            target="_blank"
            rel="noopener noreferrer"
            css={theme.mq({
              display: 'block',
              background: theme.colors.lightGreen,
              border: `1px solid ${theme.colors.lightGreen}`,
              borderRadius: 20,
              color: theme.colors.black,
              width: '100%',
              textAlign: 'center',
              padding: theme.space[2],
              marginTop: theme.space[3]
            })}
          >
            Open Spotify
          </a>
        </div>
      ) : (
        <div
          css={theme.mq({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          })}
        >
          <p
            css={theme.mq({
              marginBottom: theme.space[3]
            })}
          >
            {artistCount} {artistCount > 1 ? 'ARTISTS' : 'ARTIST'} -{' '}
            {trackCount} SONGS
          </p>
          <label htmlFor="playlistName" />
          <input
            placeholder="Playlist Name"
            type="text"
            ref={register({ required: true })}
            defaultValue={playlistName}
            name="playlistName"
            id="playlistName"
            autoComplete="off"
            css={theme.mq({
              border: `1px solid ${theme.colors.gray}`,
              borderRadius: 2,
              marginBottom: theme.space[3],
              height: 35,
              width: '100%',
              paddingLeft: theme.space[1]
            })}
          />
          <button
            onClick={() => onCreatePlaylist()}
            css={theme.mq({
              border: `1px solid ${theme.colors.black}`,
              borderRadius: 2,
              background: theme.colors.black,
              color: theme.colors.lightGreen,
              height: 35
            })}
          >
            Create
          </button>
          {!user && (
            <button
              css={theme.mq({
                border: `1px solid ${theme.colors.black}`,
                borderRadius: 2,
                background: theme.colors.black,
                color: theme.colors.lightGreen,
                height: 35,
                marginTop: '.5rem'
              })}
              onClick={() => {
                const stateCode = uuid();
                window.localStorage.setItem(
                  stateCode,
                  JSON.stringify({ searchState, concertsState })
                );
                handleLogin(stateCode);
              }}
            >
              Sign In to Spotify
            </button>
          )}
        </div>
      )}
    </Modal>
  );
};

PlaylistModal.propTypes = {
  register: PropTypes.func,
  onCreatePlaylist: PropTypes.func,
  watch: PropTypes.func,
  searchState: PropTypes.object,
  concertsState: PropTypes.object,
  concertsActions: PropTypes.object
};

export default PlaylistModal;
