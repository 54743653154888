import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import theme from 'config/theme';
import { lighten, darken } from 'polished';

const core = {
  white: '#fff',
  gray: '#484848',
  grayLight: '#82888a',
  grayLighter: '#cacccd',
  grayLightest: '#f2f2f2',

  borderMedium: '#c4c4c4',
  border: theme.colors.gray,
  borderLight: '#e4e7e7',
  borderLighter: '#eceeee',
  borderBright: '#f4f5f5',

  primary: theme.colors.green,
  primaryShade_1: lighten(0.05, theme.colors.green),
  primaryShade_2: lighten(0.08, theme.colors.green),
  primaryShade_3: lighten(0.1, theme.colors.green),
  primaryShade_4: lighten(0.5, theme.colors.green),
  primary_dark: darken(0.1, theme.colors.green),

  secondary: darken(0.05, theme.colors.green),

  yellow: '#ffe8bc',
  yellow_dark: '#ffce71'
};

export default {
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottomFocused: `2px solid ${core.primary}`
      }
    },
    color: {
      ...DefaultTheme.reactDates.color,
      border: core.border,
      hoveredSpan: {
        backgroundColor: core.primaryShade_4,
        backgroundColor_active: core.primaryShade_3,
        backgroundColor_hover: core.primaryShade_4,
        borderColor: core.primaryShade_3,
        borderColor_active: core.primaryShade_3,
        borderColor_hover: core.primaryShade_3,
        color: core.secondary,
        color_active: core.secondary,
        color_hover: core.secondary
      },
      selectedSpan: {
        ...DefaultTheme.reactDates.color.selectedSpan,
        backgroundColor: core.primaryShade_2,
        backgroundColor_active: core.primaryShade_1,
        backgroundColor_hover: core.primaryShade_1,
        borderColor: core.primaryShade_1,
        borderColor_active: core.primary,
        borderColor_hover: core.primary
      },
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: core.primary,
        backgroundColor_active: core.primary,
        backgroundColor_hover: core.primary,
        borderColor: core.primary,
        borderColor_active: core.primary,
        borderColor_hover: core.primary
      }
    }
  }
};
