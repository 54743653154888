const userKey = 'np-user';
import { SPOTIFY_AUTH_URL } from './constants.js';
import { get } from 'services/request';

const getUser = () => {
  const user = JSON.parse(window.localStorage.getItem(userKey));
  if (!user) {
    return Promise.resolve(null);
  } else {
    return Promise.resolve(user);
  }
};

const login = prevStateCode => {
  window.location = prevStateCode
    ? `${SPOTIFY_AUTH_URL}&state=${prevStateCode}`
    : SPOTIFY_AUTH_URL;
};

const callback = code => {
  return get(`/auth?code=${code}`).then(res => {
    const userWithCode = { ...res, code };
    window.localStorage.setItem(userKey, JSON.stringify(userWithCode));
    return userWithCode;
  });
};

const getCode = () => {
  const user = JSON.parse(window.localStorage.getItem(userKey));
  return user && user.code ? user.code : null;
};

export { login, getCode, getUser, callback };
