import React from 'react';
import { withRouter } from 'react-router-dom';
import useSearch from 'hooks/useSearch';
import SearchForm from 'components/search/SearchForm';
import Container from 'components/shared/Container';
import Concerts from 'components/concerts/Concerts';

const Home = () => {
  const [{ locationDescription, isExpanded }] = useSearch();

  return (
    <Container
      css={theme =>
        theme.mq({
          position: 'relative',
          top: [...theme.headerHeight],
          width: '100%',
          maxWidth: [720, 1080],
          margin: '0 auto',
          padding: theme.space[3],
          paddingBottom: 0,
          ['svg']: {
            height: '16px'
          }
        })
      }
    >
      <h1
        className="formHeading"
        css={theme =>
          theme.mq({
            height: theme.headingHeight,
            fontSize: theme.fontSize[4]
          })
        }
      >
        {locationDescription
          ? 'Choose artists to discover'
          : 'Search for upcoming shows'}
      </h1>
      <SearchForm
        isExpanded={isExpanded}
        locationDescription={locationDescription}
      />
      <Concerts locationDescription={locationDescription} />
    </Container>
  );
};

export default withRouter(Home);
