import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import StyledLocationPicker from './StyledLocationPicker';
import DropdownList from './DropdownList';
import {
  getLocationFromZipcode,
  getLocationOptions
} from 'services/location-api';
import LoadingSpinnerAlt from 'components/shared/LoadingSpinnerAlt';
import useSearch from 'hooks/useSearch';
import useDebounce from 'hooks/useDebounce';
import {
  DELAY_FOR_LOCATION_LOADING,
  DELAY_FOR_DEBOUNCE
} from 'utils/constants';

const LocationPicker = ({ setValue }) => {
  const [location, setLocation] = useState(null);
  const [typedInput, setTypedInput] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [sessionToken, setSessionToken] = useState(uuid());

  const [
    { locationOptionsLoading },
    { setLocationOptionsLoading }
  ] = useSearch();

  const locationSearchEl = useRef(null);

  const handleClickOutside = event => {
    if (
      locationSearchEl.current &&
      !locationSearchEl.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  // TODO: turn these useEffects into custom hooks
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (location) {
      setValue('locationPicker', location);
      setSessionToken(uuid());
    }
  }, [location]);

  const debouncedSearchTerm = useDebounce(
    typedInput.trim(),
    DELAY_FOR_DEBOUNCE
  );

  useEffect(() => {
    // only search if more than 2 chars
    if (debouncedSearchTerm.length > 2) {
      const locationOptionsLoadingTimeout = setTimeout(
        () => setLocationOptionsLoading(true),
        DELAY_FOR_LOCATION_LOADING
      );

      // only search if it's a zipcode or only chars
      if (Number(debouncedSearchTerm) && debouncedSearchTerm.length === 5) {
        getLocationFromZipcode(debouncedSearchTerm).then(location => {
          clearTimeout(locationOptionsLoadingTimeout);
          setLocationOptionsLoading(false);
          setLocationOptions([location]);
        });
      } else if (!debouncedSearchTerm.match(/\d/)) {
        getLocationOptions(debouncedSearchTerm, sessionToken).then(
          locations => {
            clearTimeout(locationOptionsLoadingTimeout);
            setLocationOptionsLoading(false);
            setLocationOptions(locations);
          }
        );
      }
    }
  }, [debouncedSearchTerm]);

  return (
    <StyledLocationPicker
      ref={locationSearchEl}
      className="locationInputContainer"
    >
      <input
        placeholder="City or Zip Code"
        value={location ? location.description : typedInput}
        onFocus={() => {
          setDropdownOpen(true);
          setLocation(null);
        }}
        onChange={({ target }) => setTypedInput(target.value)}
        className="locationInput"
      />
      {locationOptionsLoading && <LoadingSpinnerAlt />}
      {dropdownOpen && (
        <DropdownList
          locationOptions={locationOptions}
          setLocation={setLocation}
          setDropdownOpen={setDropdownOpen}
          sessionToken={sessionToken}
        />
      )}
    </StyledLocationPicker>
  );
};

LocationPicker.propTypes = {
  setValue: PropTypes.func.isRequired
};

export default LocationPicker;
