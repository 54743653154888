import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// https://codepen.io/lopis/pen/zwprzP

const StyledLoadingSpinnerAlt = styled.div(({ theme }) => {
  return theme.mq({
    ['.icon-container']: {
      position: 'absolute',
      right: '10px',
      top: 'calc(50% - 10px)'
    },
    ['.loader']: {
      position: 'relative',
      height: '20px',
      width: '20px',
      display: 'inline-block',
      animation: 'around 5.4s infinite'
    },
    ['@keyframes around']: {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
    ['.loader::after, .loader::before']: {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: '100%',
      height: '100%',
      borderWidth: '2px',
      borderColor: `${theme.colors.lightGreen} ${theme.colors.lightGreen} transparent transparent`,
      borderStyle: 'solid',
      borderRadius: '20px',
      boxSizing: 'border-box',
      top: 0,
      left: 0,
      animation: 'around 0.7s ease-in-out infinite'
    },
    ['.loader::after']: {
      animation: 'around 0.7s ease-in-out 0.1s infinite'
    }
  });
});

const LoadingSpinner2 = ({ theme }) => {
  return (
    <StyledLoadingSpinnerAlt theme={theme}>
      <div className="icon-container">
        <i className="loader"></i>
      </div>
    </StyledLoadingSpinnerAlt>
  );
};

LoadingSpinner2.propTypes = {
  theme: PropTypes.object
};

export default LoadingSpinner2;
